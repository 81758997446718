.skeleton {
    color: none;
    background-color: #f5f5f5;
}

.skeleton-invis {
    background-color: none;
}

/* .pulse {
    background-position: 0% 0%;
    background-size: 400% 400%;
    background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
    animation: pulse 1.2s ease-in-out infinite;
}
@keyframes pulse {
    0% {
        background-position: 0% 0%;
   }
    100% {
        background-position: -135% 0%;
   }
}

.lines {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.lines .line, .lines .thumb {
    background-color: #f5f5f5;
    width: 15rem;
    height: 1rem;
    margin: 0.25rem;
}
.lines .thumb {
    height: 7.5rem;
} */