@import url("https://use.typekit.net/ylf3zwy.css");

body {
  overflow-x: hidden;
  left: 0;
  right: 0;
  margin: 0;
  min-height: 100%;
  font-family: futura-pt, sans-serif;
  background-color: white;
  color: var(--primary-clr);
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--error-clr);
  background-color: var(--error-bg);
  width: 100%;
  height: 30px;
  margin: 15px 0;
}

.muted {
  color: var(--muted);
}

/* Authentication */
.auth-page-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-card {
  background-color: var(--secundary-bg);
  padding: var(--card-padding);
  width: min(250px, 60vw);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form {
  width: 100%;
}

.auth-form-group {
  margin-bottom: 15px;
}

.auth-form-label {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}

.auth-form-input {
  width: calc(100% - 20px);
  border: none;
  height: 30px;
  padding: 0px 10px;
}

.auth-form-input[type='password'] {
  letter-spacing: -4px;
}

.button-stretch {
  width: 100%;
  color: white;
  background-color: var(--primary-clr);
  font-weight: 600;
  border: none;
  height: 35px;
  margin-bottom: 5px;
  display: inline-block;
}

.button-outline {
  width: 100%;
  color: var(--primary-clr);
  background-color: transparent;
  font-weight: 600;
  outline: 3px solid var(--primary-clr);
  outline-offset: -3px;
  height: 35px;
  padding: 0px 20px;
}

button:disabled {
  background-color: rgb(145, 145, 145);
}

.switch-auth-domain {
  margin-top: 10px;
  color: black;
}

/* Dashboard */

.dashboard-wrapper {
  display: flex;
  flex-direction: row;
}

.main-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 40px 25px 25px 25px;
}

/* SideMenu */

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
  height: calc(100vh - 80px);
  background-color: var(--secundary-bg);
  padding: 40px;
}

.logo {
  font-family: rift, sans-serif;
  font-size: 45px;
  margin-bottom: 50px;
}

.menu-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0px;
  cursor: pointer;
}

.menu-link-wrapper.active p {
  color: var(--primary-clr);
}

.menu-link-wrapper:hover {
  color: var(--primary-clr);
  opacity: 70%;
}

.menu-link-text {
  color: var(--muted);
  font-weight: 700;
  font-size: 20px;
}

.menu-link-icon {
  width: 25px;
  margin-right: 15px;
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(90%);
}

/* Dasboard Page */

.dashboard-section-wrapper {
  opacity: 100;
  width: 100%;
  height: 100%;
}

.dashboard-section-title {
  color: var(--primary-clr);
  margin-bottom: 20px;
  font-size: 30px;
}

/* Course Card */

.course-card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: var(--secundary-bg);
  width: 500px;
  min-height: 150px;
  margin: 0 15px 15px 0;
  padding: var(--card-padding);
}

.course-card:hover {
  opacity: 70%;
}

.course-card-left {
  height: 100%;
  width: 150px;
  background-size: cover;
  background-position: center;
}

.course-card-content {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: calc(500px - 150px);
}

.course-card-description {
  color: var(--muted);
}

.course-card-footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}

.course-card-button {
  background-color: var(--secundary-clr);
  color: white;
  border: none;
  padding: 10px 20px;
}

.course-card-more-info {
  color: var(--muted);
}

.courses-welcome-title {
  color: black;
  font-size: 45px;
}

.courses-welcome-title span {
  font-weight: 500;
}

.courses-welcome-wrapper {
  margin-bottom: 25px;
}

.courses-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--muted);
  width: 11px;
  height: 11px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Beginners Course */

.tab-links-wrapper {
  display: flex;
  flex-direction: row;
  align-items: bottom;
  margin-bottom: 30px;
}

.tab-link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
  cursor: pointer;
}

.tab-link-text {
  font-weight: 600;
  color: var(--muted);
  font-size: 20px;
}

.tab-link-wrapper.active h4 {
  color: var(--primary-clr);
}

.tab-link-wrapper.active .tab-link-underline {
  height: 3px;
  width: 40%;
  background-color: var(--primary-clr);
}

.file-download-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--secundary-bg);
  padding: var(--card-padding);
  margin-bottom: 40px;
  cursor: pointer;
  text-decoration: none;
}

.download-icon {
  width: 20px;
  margin-right: 25px;
}

.file-download-text {
  color: var(--muted);
  margin: 0;
}

.beginners-live-progress-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.progress-bar-text-wrapper {
  transform: translateY(-10px);
}

.progress-bar-title {
  color: var(--muted);
}

.progress-bar-subtitle {
  color: var(--muted);
  font-size: 20px;
  font-weight: 500;
}

.beginners-live-sessions-cards-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.beginners-course-card {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: var(--secundary-bg);
  width: min(450px,90%);
  height: 200px;
  margin: 0 15px 15px 0;
  padding: var(--card-padding);
}

.beginners-course-card-content {
  display: flex;
  flex-direction: column;
  /* margin-left: 15px; */
  /* width: calc(500px - 150px); */
}

.beginners-course-card-title {
  margin-bottom: 0px;
  margin-top: 15px;
}

.beginners-course-card-description {
  color: var(--muted);
}

#beginnersCoursePreviousSession {
  position: relative;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/wow-by-ann.appspot.com/o/course-images%2FElsHuartPhotography-2176.jpg?alt=media&token=cea7d811-7ce9-4079-8e0c-a23b46b35123");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.beginners-course-previous-session-overlay {
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(190deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
}

.beginners-course-card-title {
  z-index: 1;
  color: var(--primary-clr);
}

.play-button-image {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
}

.youtube-video-embed {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;
}

.free-session-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

/* Progress Bar */

.progress-bar-wrapper {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-outer-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: calc(100% - calc(4 * 30px));
  height: calc(100% - calc(4 * 30px));
  border: 25px solid var(--secundary-bg);
  border-bottom-color: transparent;
}

.progress-inner-circle {
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 0;
  border-radius: 50%;
  width: calc(100% - calc(4 * 30px));
  height: calc(100% - calc(4 * 30px));
  border: 25px solid var(--primary-clr);
  /* transform: rotate(-150deg); */
}

.progress-inner-circle-0 {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.progress-inner-circle-1 {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
.progress-inner-circle-2 {
  border-right-color: transparent;
  border-top-color: transparent;
}
.progress-inner-circle-3 {
  border-right-color: transparent;
}

.progress-bar-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: calc((100% - calc(4 * 30px)) - 2px);
  height: calc((100% - calc(4 * 30px)) - 2px);
  border: 30px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

/* Admin Section */

.live-beginners-course-card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: space-between;
  background-color: var(--secundary-bg);
  padding: var(--card-padding);
  width: min(80%, 400px);
  color: black;
  cursor: pointer;
  margin: 0px 20px 20px 0px;
}

.live-beginners-course-card-wrapper.active {
  outline: 5px solid var(--success-clr);
}

.live-beginners-course-card-wrapper h4 {
  margin: 0px;
}

.live-beginners-course-card-status-label.future{
  background-color: var(--warning-clr);
  color: white;
  padding: 15px;
}

.live-beginners-course-card-status-label.active{
  background-color: var(--success-clr);
  color: white;
  padding: 15px;
}

.live-beginners-course-card-status-label.past{
  background-color: var(--error-clr);
  color: white;
  padding: 15px;
}

.live-beginners-course-session-card-admin {
  background-color: var(--secundary-bg);
  padding: var(--card-padding);
  width: min(400px, 80%);
  margin-bottom: 25px;
}

.mark-as-complete-button-admin {
  background-color: var(--error-bg);
  color: var(--error-clr);
}

.live-beginners-course-lesson-card {
  background-color: var(--secundary-bg);
  padding: var(--card-padding);
  width: min(600px, 80%);
  margin: 0px 15px 15px 0px;
}

.live-beginners-course-lesson-card-header-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.live-beginners-course-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.live-beginners-course-lesson-card-session-complete {
  background-color: var(--success-bg);
  padding: 8px;
  color: var(--success-clr);
  font-weight: 600;
}

.drop-down-icon {
  width: 30px;
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(90%);
  transform: rotate(-90deg)
}

.drop-down-icon.active {
  width: 30px;
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(90%);
  transform: rotate(0deg)
}

.live-beginners-course-lesson-card-dropdown-wrapper {
  background-color: var(--secundary-bg);
  padding: var(--card-padding);
  width: min(600px, 80%);
  margin: 0px 15px 15px 0px;
  display: none;
}

.live-beginners-course-lesson-card-dropdown-wrapper.active {
  display: flex;
}

.admin-form-input {
  width: calc(100% - 20px);
  border: none;
  height: 30px;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.admin-form-input[type="date"],
.admin-form-input[type="time"] {
  width: calc(100% - 20px);
  border: none;
  height: 30px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  background-color: white;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.admin-actions-wrapper {
  position: absolute;
  display: flex;
  gap: 10px;
  bottom: 20px;
  right: 20px;
}

.hamburger {
  display: none;
}

.side-menu-overlay {
  display: none;
}

@media only screen and (max-width: 900px) {

  .side-menu {
    z-index: 1000;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.128);
    position: fixed;
    top: 0;
    left: 0;
    transition: all 300ms ease;
  }

  .side-menu.opened {
    left: 0;
    transition: all 300ms ease;
  }

  .side-menu.closed {
    left: -250px;
    transition: all 300ms ease;
  }

  .side-menu.opened .hamburger {
    display: none;  
    position: absolute;
    top: 10px;
    right: -30px;
    font-weight: 600;
    color: black;
  }

  .side-menu-overlay {
    z-index: 100;
    position: fixed;
    display: flex;
    background-color: rgba(0, 0, 0, 0.261);
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    transition: all 300ms ease;
  }

  .side-menu-overlay.closed {
    pointer-events: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    transition: all 300ms ease;
  }

  .hamburger {
    padding: 10px;
    display: block;
    position: absolute;
    top: 0px;
    right: -40px;
    font-weight: 600;
    color: black;
  }

  .courses-wrapper {
    gap: 15px;
  }

  .course-card {
    margin: 0;
    max-width: 90%;
    padding: 15px;
    min-height: auto;
  }

  .tab-links-wrapper {
    flex-direction: column;
    align-items: start;
    gap: 15px;
    width: auto;
  }

  .tab-link-text {
    margin-bottom: 5px;
  }

  .course-card-title {
    font-size: 25px;
    margin: 0;
    line-height: 25px;
  }

  .course-card-left {
    width: 120px;
    /* height: 120px; */
  }

  .course-card-button {
    padding: 8px 15px;
  }

  .main-content {
    padding: 45px 15px 15px 15px;
  }

  .live-beginners-course-lesson-card {
    /* width: calc(100% - 30px);
    padding: 15px; */
  }

  .live-beginners-course-lesson-card h2 {
    font-size: 20px;
  }

  .progress-bar-wrapper {
    width: 180px;
    height: 180px;
  }

  .progress-bar-title {
    font-size: 25px;
  }

  .file-download-card-wrapper {
    padding: 15px;
  }

  .file-download-text {
    font-size: 15px;
  }

  .beginners-live-sessions-cards-wrapper {
    gap: 15px;
  }

  .beginners-course-card {
    margin: 0;
    padding: 15px;
    width: min(100%, 300px)
  }

  .beginners-course-card-title {
    font-size: 25px;
  }

}